$primary: #233869;
$body-color: #1e1e1e;
$red: #f15a54;
$yellow: #fec63e;
$green: #1bb36e;

$font-family-sans-serif: system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
  'Liberation Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
$font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;

$h1-font-size: 72px;
$headings-font-weight: 300;

$table-cell-padding-y: 30px;
$table-group-separator-color: rgb(184, 184, 184);
$table-cell-vertical-align: center;

$table-cell-padding-y-sm: 1rem;

@import './nprogress';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/maps';
@import 'bootstrap/scss/utilities';

$utilities: map-merge(
  $utilities,
  (
    'cursor': (
      property: cursor,
      class: cursor,
      responsive: true,
      values: auto pointer grab
    )
  )
);

@import '~bootstrap/scss/bootstrap';

.btn-success {
  color: white;
}

.form-control::placeholder {
  color: #8f8f8f;
  font-style: italic;
  font-size: 16px;
}

.table > :not(caption) > * > th {
  padding: 15px 0.5rem;
}

.btn.btn-danger {
  color: white;
}

.space-x-2 {
  & > :not([hidden]) ~ :not([hidden]) {
    margin-left: 5px;
  }
}

.space-x-3 {
  & > :not([hidden]) ~ :not([hidden]) {
    margin-left: 8px;
  }
}

.space-x-4 {
  & > :not([hidden]) ~ :not([hidden]) {
    margin-left: 12px;
  }
}

.space-x-5 {
  & > :not([hidden]) ~ :not([hidden]) {
    margin-left: 16px;
  }
}

.space-x-6 {
  & > :not([hidden]) ~ :not([hidden]) {
    margin-left: 24px;
  }
}

.space-y-2 {
  & > :not([hidden]) ~ :not([hidden]) {
    margin-top: 5px;
  }
}

.space-y-3 {
  & > :not([hidden]) ~ :not([hidden]) {
    margin-top: 8px;
  }
}

.space-y-4 {
  & > :not([hidden]) ~ :not([hidden]) {
    margin-top: 12px;
  }
}

.page {
  padding-bottom: 120px;
}
